.btn {
  position: relative;

  .spinner {
    position: absolute;
    left: 10px;
    transform: translateY(-50%);
    top: 41%;
  }
}

.btn-primary {
  @include button-variant(#13a55f, #13a55f, white, #13a55f, $hover-color: white, $disabled-color: white);

  &.nav-link {
    color: white;
  }
}

.btn-outline-primary {
  @include button-outline-variant(#13a55f, white, #13a55f, darken(#13a55f, 5%), white);
}

.cursor-pointer {
  cursor: pointer;
}

.hover-shine-style {
  display: block;
  position: relative;
  cursor: pointer;
  transition: box-shadow .3s;

  &:after {
    content: '';
    position: absolute;
    top: 0;
    left: 0;
    width: 0;
    height: 100%;
    background-color: rgba(255, 255, 255, 0.4);
    -webkit-transition: none;
    -moz-transition: none;
    transition: none;
  }

  &:hover:after {
    width: 120%;
    background-color: rgba(255, 255, 255, 0);
    -webkit-transition: all 0.4s ease-in-out;
    -moz-transition: all 0.4s ease-in-out;
    transition: all 0.4s ease-in-out;
  }
}

.hover-shadow-style {
  box-shadow: 0px 0px 5px 0px rgba(0, 0, 0, 0.25);
  background: linear-gradient(170deg, rgba(210, 210, 210, 1) 0%, rgba(240, 240, 240, 1) 35%, rgba(255, 255, 255, 1) 100%);
  border: 1px solid #d2d2d2;

  &.--flag-danger {
    background: linear-gradient(180deg, rgba(255, 75, 75, 1) 0%, rgba(255, 159, 159, 1) 30%, rgba(255, 230, 230, 1) 54%, rgba(255, 255, 255, 1) 77%);
    border: 1px solid #ff8282;
  }

  &.--flag-warning {
    background: linear-gradient(180deg, rgba(255, 174, 75, 1) 0%, rgba(255, 228, 159, 1) 30%, rgba(255, 249, 221, 1) 54%, rgba(255, 255, 255, 1) 77%);
    border: 1px solid #ffda82;
  }

  &:hover {
    box-shadow: 0 0 11px rgba(33, 33, 33, .4) !important
  }

}

.card-alert,
.card-metric-kpi {
  box-shadow: 0px 0px 5px 0px rgba(0, 0, 0, 0.25);
  border: 1px solid #d2d2d2;
  border-top: 8px solid #d2d2d2;

  &:hover {
    box-shadow: 0 0 11px rgba(33, 33, 33, .4);
  }

  &.--flag-danger {
    border-top: 8px solid #e74c3c;
  }

  &.--flag-warning {
    border-top: 8px solid #f1c40f;
  }

  &.--flag-success {
    border-top: 8px solid #13a55f;
  }
}

.card-metric-kpi {
  &.--flag-danger {
    border-top: 8px solid #e74c3c;
  }

  &.--flag-warning {
    border-top: 8px solid #f1c40f;
  }

  &.--flag-success {
    border-top: 8px solid #13a55f;
  }
}
