@media print {
    @for $i from 1 through 12 {
        .col-print-#{$i} {
            width: #{percentage(round($i*8.33)/100)};
            float: left;
        }
    }
    table td, table th {
        font-size: 10px!important;
        padding: 0px!important;
    }

    h1, h2, h3, h4, h5, h6{
        font-size: 13px!important;
    }
    p, span, div, a{
        font-size: 10px!important;
    }


}

