
.import-table-container {
    overflow-x: scroll;
    text-align: center;
    .import-table {
      // width: 2000px;
      margin: auto;
    }
  }
  
  #interactive {
    position: relative;
    max-width: 640px;
    max-width: 19rem;
    video,
    canvas {
      width: 100%;
    }
  }
  canvas.drawing,
  canvas.drawingBuffer {
    position: absolute;
    left: 0;
    top: 0;
  }
  .import-table-container {
    overflow-x: scroll;
    text-align: center;
    .import-table {
      // width: 2000px;
      margin: auto;
    }
  }