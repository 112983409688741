.modal {
    // --bs-modal-zindex: 1055;
    // --bs-modal-width: 500px;
    // --bs-modal-margin: 0.5rem;
    // --bs-modal-color: ;
    // --bs-modal-border-color: var(--bs-border-color-translucent);
    // --bs-modal-border-width: 1px;
    // --bs-modal-border-radius: 0.5rem;
    // --bs-modal-box-shadow: 0 0.125rem 0.25rem rgba(0,0,0,.075);
    // --bs-modal-inner-border-radius: calc(0.5rem - 1px);
    // --bs-modal-header-padding-x: 1rem;
    // --bs-modal-header-padding-y: 1rem;
    // --bs-modal-header-padding: 1rem 1rem;
    // --bs-modal-header-border-color: var(--bs-border-color);
    // --bs-modal-header-border-width: 1px;
    // --bs-modal-title-line-height: 1.5;
    // --bs-modal-footer-gap: 0.5rem;
    // --bs-modal-footer-bg: #f4f9ff;
    // --bs-modal-footer-border-color: var(--bs-border-color);
    --bs-modal-bg:  #f4f9ff;
    --bs-modal-footer-border-width: 0px;
    --bs-modal-bg: #f4f9ff;
    --bs-modal-header-border-width: 0px;
  }

  .btn-close{
    border-radius: 50%;
    opacity: 0.9;
    box-shadow: 0px 0px 10px 2px rgba(0,0,0,0.1);
    background-size: 0.8rem;
    background-color: white;
    background-image: url("data:image/svg+xml,%3csvg xmlns=%27http://www.w3.org/2000/svg%27 viewBox=%270 0 16 16%27 fill=%27%2310a458%27%3e%3cpath d=%27M.293.293a1 1 0 0 1 1.414 0L8 6.586 14.293.293a1 1 0 1 1 1.414 1.414L9.414 8l6.293 6.293a1 1 0 0 1-1.414 1.414L8 9.414l-6.293 6.293a1 1 0 0 1-1.414-1.414L6.586 8 .293 1.707a1 1 0 0 1 0-1.414z%27/%3e%3c/svg%3e") ;
    &:hover{
      background-color: #10a458;
      background-image: url("data:image/svg+xml,%3csvg xmlns=%27http://www.w3.org/2000/svg%27 viewBox=%270 0 16 16%27 fill%3D%27%23ffffff%27%3E%3Cpath d=%27M.293.293a1 1 0 0 1 1.414 0L8 6.586 14.293.293a1 1 0 1 1 1.414 1.414L9.414 8l6.293 6.293a1 1 0 0 1-1.414 1.414L8 9.414l-6.293 6.293a1 1 0 0 1-1.414-1.414L6.586 8 .293 1.707a1 1 0 0 1 0-1.414z%27/%3e%3c/svg%3e") ;

    }
  }
  .modal-title{
    font-size: 1.75rem;
    font-weight: 700;
  }

  .modal-backdrop {
    --bs-backdrop-opacity: 0.6;
  }