/*define styles here*/
@import "node_modules/bootstrap/scss/functions";
$body-color: #444;
$close-color: $body-color;
$super-light: rgba(white, 0.02);
$orange: #f8ae0a;
$secondary: #6c757d;
$light: #e4e4e4;
$primary: #13a55f;
$active: #10209a;

$font-family-sans-serif: "Poppins", sans-serif;
$enable-rounded: true;
$enable-responsive-font-sizes: true;
$enable-negative-margins: true;

.bg-gray{
  background: #f4f9ff;
}
$container-max-widths: (
  sm: 100%,
  md: 100%,
  lg: 100%,
  xl: 1680px,
  xxl: 1680px
);

@import "./bootstrap-variables";
@import "node_modules/bootstrap/scss/variables";
@import "node_modules/bootstrap/scss/mixins";
@import "node_modules/bootstrap/scss/bootstrap";

:root {
  --bs-blue: #0d6efd;
  --bs-active: $active;
  --bs-indigo: #6610f2;
  --bs-purple: #6f42c1;
  --bs-pink: #d63384;
  --bs-red: #dc3545;
  --bs-orange: #fd7e14;
  --bs-yellow: #ffc107;
  --bs-green: #13a55f;
  --bs-teal: #20c997;
  --bs-cyan: #0dcaf0;
  --bs-white: #fff;
  --bs-gray: #6c757d;
  --bs-gray-dark: #343a40;
  --bs-primary: #13a55f;
  --bs-link-color: #13a55f;
  --bs-secondary: #6c757d;
  --bs-success: #13a55f;
  --bs-info: #0dcaf0;
  --bs-warning: #ffc107;
  --bs-danger: #dc3545;
  --bs-light: #535c4d;
  // --bs-light-rgb: 83, 92, 77;
  --bs-dark: #212529;
  --bs-font-sans-serif: "Poppins", sans-serif;
  --bs-font-monospace: SFMono-Regular, Menlo, Monaco, Consolas, "Liberation Mono", "Courier New", monospace;
  --bs-gradient: linear-gradient(180deg, rgba(255, 255, 255, 0.15), rgba(255, 255, 255, 0));
  --bs-btn-hover-color: white;
  --bs-btn-border-radius: 0.3rem;
  --bs-border-radius: 0.5rem;

}

.wrapper {
  min-height: 100vh;
  position: relative;
}

.rounded {
  border-radius: 1em;
}

.clickable :hover {
 cursor: pointer !important
}

.v-align-mid {
  vertical-align: middle
}

button {
  p {
    display: flex;
    align-items: center;
    justify-content: space-around;
  }
}
a{
  text-decoration: none!important;
}

@import "./_atron.scss";
@import "./_buttons.scss";
@import "./_print.scss";
@import "./_camera.scss";
@import "./_footer.scss";
@import "./_forms.scss";
@import "./_header.scss";
@import "./_opacity.scss";
@import "./_spreadsheet.scss";
@import "./_typography.scss";
@import "./_modals.scss";


