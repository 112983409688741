
.react-html5-camera-photo {
    > img,
    > video {
      width: 100% !important;
    }
  }

  .camera-orc {
    &.-front {
      #white-flash {
        // background-image: url(/img/outlines/6.front-outline-def.png);
        background-size: auto 100%;
        background-repeat: no-repeat;
        background-position: center;
      }
    }
    &.-back {
      #white-flash {
        // background-image: url(/img/outlines/5.back-outline-def.png);
        background-size: auto 100%;
        background-repeat: no-repeat;
        background-position: center;
      }
    }
  }