.navbar-nav {
    .link {
      color: $gray-900 !important;
      &.active,
      &:hover {
        color: $primary !important;
      }
    }
  }
  
.header-progress {
    &__link {
      text-decoration: none !important;
    }
  }