.avocado {
    &__counter {
      width: 1.5rem;
      display: inline-block;
    }
    &__img {
      width: 4rem;
      display: inline-block;
      height: 4rem;
      vertical-align: middle;
      &.-holder {
      }
    }
    &__camera {
      > * {
        max-width: 19rem;
        margin: auto;
      }
      &-toggle {
        &-aligner {
          position: relative;
          z-index: 999;
        }
        position: absolute;
        top: 0;
        right: 0;
      }
    }
  }

  .pressure {
    &__avocado {
      display: inline;
      height: 40px;
      &.-item-1 {
        transform: rotate(45deg);
      }
      &.-item-2 {
        transform: rotate(-110deg) scale(1, 1);
      }
      &.-item-3 {
        transform: rotate(-78deg);
      }
    }
    &__border {
      border-bottom: 3px solid #eee;
    }
  }
  .grayscale-filter{
    filter: gray; /* IE6-9 */
    -webkit-filter: grayscale(1); /* Google Chrome, Safari 6+ & Opera 15+ */
  filter: grayscale(1);
  }

  .quick-pressure-box{
    width:300px;
  }

  .relabeling_session_manual{
    border: 5px solid #ececec;
    padding: 10px;
  }