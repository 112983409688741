$assetPath :"/img";
.footer {
    position: relative;
    width: 100%;
    img {
      width: 1.8rem;
      margin-bottom: 0.5rem;
    }
    .footer-copyright{
      position: absolute;
      width: 100%;
    }
    .testing-block{
      position: absolute;
      bottom: 0;
      width: 100%;
      padding: 10px 0;
    }
  }
  
.stripes{
  position: absolute;
  height: 10rem;
  width: 100%;
  background-size: contain;
  background-image: url(#{$assetPath}/footer_bg_Eye.svg);
  background-repeat: repeat-x;
}